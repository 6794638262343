<template>
  <tfoms-panel
    title="ОБЩАЯ ОЦЕНКА КАЧЕСТВА"
    small
    hideIcon
    class="kibmd-overall-rating"
    style="width: 355px; height: 308px"
    v-bind="$attrs"
    :isClickLabel="false"
  >
    <div class="kibmd-overall-rating__title">{{ value }}%</div>
    <div class="kibmd-overall-rating__desc">
      {{ description }}
    </div>
  </tfoms-panel>
</template>
<script>
export default {
  components: {
    tfomsPanel: () => import("@/components/analysis/panel.vue"),
  },
  props: {
    value: { type: Number, default: 22 },
    description: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: true,
      doughnutData: {
        datasets: [
          {
            data: [99, 1],
            backgroundColor: ["#BBC6F0", "#FFC805"],
            hoverOffset: 4,
          },
        ],
      },
      doughnutOptions: {
        tooltips: {
          enabled: false,
        },
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      await new Promise((resolve) => {
        setTimeout(() => resolve(), 400);
      });
      this.doughnutData.datasets[0].data = [this.value, 100 - this.value];
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
.kibmd-overall-rating {
  &__desc {
    color: var(--v-blue-grey-base);
    font-size: 12px;
    line-height: 158%;
    font-weight: 500;
    text-align: center;
    height: 130px;
    overflow-y: auto;
  }
  &__title {
    color: var(--v-error-lighten3);
    text-align: center;
    font-size: 48px;
    line-height: 158%;
    font-weight: 500;
    position: relative;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0px;
  }
}
</style>
